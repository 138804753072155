import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby"
import BlogTitle from '../../components/BlogTitle/BlogTitle';
import Layout from '../../components/Layout/Layout';
import { PortfolioProvider } from '../../context/context';
import { headerData, footerData } from '../../mock/data';
import { Fade } from "react-awesome-reveal";
import { SEO } from '../../components/SEO/SEO';

const BlogPost = ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const prev = pageContext.prev
   ? {
         url: `${pageContext.prev.fields.slug}`,
         title: pageContext.prev.frontmatter.title
      }
   : null;
   const next = pageContext.next
   ? {
         url: `${pageContext.next.fields.slug}`,
         title: pageContext.next.frontmatter.title
      }
   : null

  const [header, setHeader] = useState({});
  const [footer, setFooter] = useState({});


  useEffect(() => {
    setHeader({ ...headerData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ header, footer }}>
      <Layout>
      <SEO title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description || data.markdownRemark.excerpt}/>
      <div className='pageText'>
      <BlogTitle title={post.frontmatter.title} isBlog/>
      <Fade triggerOnce duration={1000} delay={1200}>
      <h2 style={{justifyContent: 'center'}}>Date: {post.frontmatter.date} </h2>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <div className='postButtons'>
          {prev && (
              <p className="hero-cta">
                <a className="cta-btn cta-btn--hero" href={prev.url}>
                  Prev Post
                </a>
            </p>
          )}
          {next && (
              <p className="hero-cta">
                <a className="cta-btn cta-btn--hero" href={next.url}>
                  Next Post
                </a>
              </p>
          )}
        </div>
        {post.frontmatter.tags && (
          <p className='tags'>Tags: <span>{post.frontmatter.tags}</span></p>
         )}
      </Fade>
      </div>
    </Layout>
    </PortfolioProvider>
  )
}

export default BlogPost;

// export const Head = ({ data }) => (
//   <SEO title={`Nick Jeske | ${data.markdownRemark.frontmatter.title}`}
//   description={data.markdownRemark.excerpt}/>
// );

export const query = graphql`
  query($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD YYYY")
        tags
      }
      excerpt
    }
  }
`